<template>
  <div>
    <component
      :is="!mobileView && search_data.rooms.length > 1 && !enableMultiRoomBooking ? 'el-tabs' : 'div'"
      type="card"
      :class="{ 'is-active': activeRoomIndex }"
      v-model="activeRoomIndex"
    >
      <component
        :is="
          !mobileView && search_data.rooms.length > 1 && !enableMultiRoomBooking ? 'el-tab-pane' : 'div'
        "
        :label="`${ $t('lbl_room') } ${roomIndex + 1}`"
        v-for="(room, roomIndex) in !mobileView
          ? (enableMultiRoomBooking ? [search_data.rooms[0]] : search_data.rooms)
          : [this.search_data.rooms[activeRoomIndex]]"
        :key="roomIndex"
      >
        <div class="filters" v-if="!mobileView">
          <div class="date-filter">
            <DateFilter
              :searchData="room"
              @onDateSelected="onDateSelected($event, roomIndex)"
            />
          </div>
          <div>
            <GuestsFilter
              :key="guestKey"
              :roomIndex="roomIndex"
              :searchData="search_data"
              @guestsChanged="guestsChanged($event, roomIndex)"
            />
          </div>
          <div
            v-if="!!discount_applied"
            class="promotion-code flexed align-center"
            style="font-size: 16px"
          >
            <span class="tag flexed align-center"
              >{{ $t("lbl_promotion_applied") }} : {{ translateValues(discount_applied.name, discount_applied.name, 'PROMOTIONS', discount_applied.id, 'code') }}</span
            >
          </div>
          <div
            v-if="!!company_code"
            class="promotion-code flexed align-center"
            style="font-size: 16px"
          >
            <span class="tag flexed align-center"
              >{{ $t("lbl_company_code") }} : {{ company_code }}</span
            >
          </div>
          <div
            v-if="!!group_no"
            class="promotion-code flexed align-center"
            style="font-size: 16px"
          >
            <span class="tag flexed align-center" v-if="groupName"
              >{{ $t("lbl_group_name") }} : {{ groupName }}</span
            >
            <span class="tag flexed align-center" v-else
              >{{ $t("lbl_group_code") }} : {{ group_no }}</span
            >
          </div>
          <!-- <div v-else-if="applicablePackage" class="promotion-code flexed align-center" style="font-size: 16px">
            <span class="tag flexed align-center">Package Applied : {{ applicablePackage.name }}</span>
          </div> -->
        </div>
        <div v-else>
          <header-info
            :activeRoomIndex="activeRoomIndex"
            @getProfile="getProfile($event, roomIndex)"
          />
          <el-select
            v-if="search_data.rooms.length > 1"
            v-model="activeRoomIndex"
            @change="roomsKey += 1"
            class="w-100 rooms-drop-down"
            style="padding: 10px 25px 0 25px"
            :placeholder="$t('lbl_select_room')"
          >
            <el-option
              v-for="(item, index) in search_data.rooms"
              :key="index"
              :label="`${$t('Select Room')} ${index + 1}`"
              :value="index.toString()"
            >
            </el-option>
          </el-select>
        </div>
        <div
          v-if="select_room_loading"
          class="loader loading_whole"
          style="width: 100%"
        >
          <loading></loading>
        </div>
        <div v-else-if="rateCodes && roomTypes">
          <Filters :roomIndex="activeRoomIndex" />
          <points-filter
            v-if="
              logged_in_user_details &&
              logged_in_user_details.account_number &&
              logged_in_user_details.allow_redeem_points && ifRateCodeParamIsNotGoogle
            "
            @bookWithPointsStatus="bookWithPointsStatus"
            @setActiveTab="activeRoomIndex = '0'"
          ></points-filter>
          <Rooms
            ref="rooms"
            :key="roomsKey"
            :model="sortedRates"
            :rateCodes="rateCodes"
            :rateDetails="rateDetails"
            :roomTypes="roomTypes"
            :packageRateCode="packageRateCode"
            :packageChargeTypes="packageChargeTypes"
            :roomIndex="+activeRoomIndex"
            @changeRoomIndex="activeRoomIndex = $event"
          />
        </div>
      </component>
    </component>
  </div>
</template>

<script>
import format from "date-fns/format";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
// import CommonServices from '../../../services/common.services'
// import dayJs from "dayjs";
import DateFilter from "../component/filters/date-filter";
import GuestsFilter from "../component/filters/GuestsFilter";
import Rooms from "./Rooms";
import HeaderInfo from "../mobile/HeaderInfo.vue";
import loading from "@/components/common/loading.vue";
import Filters from "../component/filters/sort-by-filters/SortByFilters";
import CommonUtils from "../../../utils";
import Clone from "clone";
import pointsFilter from "../component/filters/points-filter";
import { translateDynamicContent } from "@/services/utilities.service";


export default {
  name: "SelectRoom",
  components: {
    Filters,
    Rooms,
    GuestsFilter,
    DateFilter,
    HeaderInfo,
    loading,
    pointsFilter,
  },
  mounted() {
    this.discountApplied = this.discount_applied;

    let payload = {};
    if (this.$route.query.hasOwnProperty("rate_id")) {
      payload.rate_code_ids = this.$route.query.rate_id;
    }
    if (!this.rwa_data) {
      if(navigator.geolocation && this.$route.query.skip_search) {
        this.pollTimer = setInterval(async () => {
          try {
            if(this.disableSearchButton === false) {
              this.getAvailableRates(payload);
              clearInterval(this.pollTimer);
            }
          }
          catch(error) {
            if(this.pollTimer)
              clearInterval(this.pollTimer);
              console.log(error)
          }
        }, 2000)
      }
      else {
        this.getAvailableRates(payload);
      }
    }
  },
  data() {
    return {
      loading: false,
      guestKey: 0,
      roomsKey: 1,
      activeRoomIndex: "0",
      sortByPrice: "asc",
      headerKey: 1,
      pollTimer: null
    };
  },
  computed: {
    ...mapState({
      search_data: (state) => state.search_data,
      mobileView: (state) => state.mobileView,
      discount_applied: (state) => state.discount_applied,
      select_room_loading: (state) => state.select_room_loading,
      applicablePackage: (state) => state.applicablePackage,
      rwa_data: (state) => state.rwa_data,
      rateCodes: (state) => state.rate_codes,
      rateDetails: (state) => state.rate_details,
      roomTypes: (state) => state.room_types,
      packageChargeTypes: (state) => state.package_charge_types,
      allFilters: (state) => state.allFilters,
      packageRateCode: (state) => state.packages,
      logged_in_user_details: (state) => state.logged_in_user_details,
      company_code: (state) => state.company_code,
      group_no: (state) => state.group_no,
      propertyDetails: state => state.property.details,
      disableSearchButton: state => state.disableSearchButton,
      multi_lingual_dynamic_fields: state => state.multi_lingual_dynamic_fields,
      property_language_code: state => state.property.details.language
    }),
    ifRateCodeParamIsNotGoogle(){
      return !this.$route.query?.rate_code || this.$route.query?.rate_code !== 'GOOGLE'
    },
    sortedRates() {
      let rooms = [];
      if (this.$route.query.hasOwnProperty("rn_code")) {
        let filteredRooms =
          this.rateDetails.length > 0
            ? this.rateDetails[this.activeRoomIndex]
            : [];
        let roomTypes =
          this.roomTypes.length > 0 ? this.roomTypes[this.activeRoomIndex] : [];
        let rnCode =
          this.$route.query.hasOwnProperty("rn_code") &&
          this.$route.query.rn_code;
        let preferredRoom = roomTypes.find((r) => r.code === rnCode);
        if (filteredRooms) {
          filteredRooms.forEach((room) => {
            rooms.push({
              ...room,
            });
          });
          rooms = this.getSortedRates(filteredRooms);
          if (
            preferredRoom &&
            filteredRooms.find((el) => el.room_type_id === preferredRoom.id)
          ) {
            let ratesOfPreferredRoom = filteredRooms.filter(
              (el) => el.room_type_id === preferredRoom.id
            );
            rooms = rooms.filter((el) => el.room_type_id != preferredRoom.id);
            rooms.unshift(...ratesOfPreferredRoom);
          }
        }
        this.changeRoomKey();
        return rooms;
      } else {
        let filteredRooms =
          this.rateDetails.length > 0
            ? this.rateDetails[this.activeRoomIndex]
            : [];
        rooms = this.getSortedRates(filteredRooms);
        return rooms;
      }
    },
    priceSort() {
      return this.allFilters[this.activeRoomIndex]?.sortByPrice;
    },
    groupName() {
      return this.rwa_data?.group_block?.group_name;
    },
    enableMultiRoomBooking() {
      return this.propertyDetails.website_config?.enable_multi_room_booking
    }
  },
  watch: {
    mobileView() {
      this.$forceUpdate();
    },

    "search_data.checkOut"() {
      let payload = {};
      if (this.$route.query.hasOwnProperty("rate_id")) {
        payload.rate_code_ids = this.$route.query.rate_id;
      }
    },
    priceSort(value) {
      this.sortByPrice = value;
    },
    activeRoomIndex() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
  methods: {
    ...mapMutations(["SET_SEARCH_DATA", "SET_RATE_CONTENT"]),
    ...mapActions([
      "getAvailableRates",
      "changeRoomData",
      "getRoomAvailableRates",
      "updateSelectedRoom",
    ]),
    getSortedRates(filteredRooms) {
      let rooms = [];
      if (filteredRooms) {
        filteredRooms.forEach((room) => {
          room.rate_codes?.forEach((r) => {
            r.rate_amount = r.price_summary.total_amount;
          });
          room?.package_rate_codes?.forEach((r) => {
            r.rate_amount = r.price_summary.total_amount;
          });

          room?.package_rate_codes?.forEach((rc) => {
            if (
              !room.rate_codes?.find(
                (e) => e.package_id === rc.package_id && e.id === rc.id
              )
            ) {
              if(room.rate_codes)
                room.rate_codes.push(rc);
              else room.rate_codes = [rc]
            }
          });

          if(room.rate_codes)
            room.rate_codes = CommonUtils.sortFunction(
              room.rate_codes,
              this.sortByPrice,
              `rate_amount`
            );
          if (
            this.applicablePackage &&
            room.rate_codes.filter(
              (r) => r.package_id === this.applicablePackage.id
            ).length
          ) {
            let packageToBePlacetOnTop = room.rate_codes.filter(
              (r) => r.package_id === this.applicablePackage.id
            );
            room.rate_codes = room.rate_codes.filter(
              (r) => r.package_id != this.applicablePackage.id
            );
            room.rate_codes.unshift(...packageToBePlacetOnTop);
          }

          rooms.push({
            ...room,
            highest_rate: room.rate_codes ? Math.max(
              ...room.rate_codes.map((r) => {
                if (r.price_summary.total_amount < 1) return 0;
                return r.price_summary.total_amount;
              })
            ) : 0,
          });
        });
        // applicablePackage
        if(rooms)
          rooms = CommonUtils.sortFunction(
            rooms,
            this.sortByPrice,
            "highest_rate"
          );
      }
      this.changeRoomKey();
      return rooms;
    },
    onDateSelected(selectedDates, roomIndex) {
      let payload = {
        selectedDates,
        roomIndex,
      };
      if (this.$route.query.hasOwnProperty("rate_id")) {
        payload.rate_code_ids = this.$route.query.rate_id;
      }

      this.removeRoom();
      this.changeRoomData(payload);
      this.getRoomAvailableRates(payload);
    },
    guestsChanged(item, roomIndex) {
      // console.log('item',item)
      let searchData = Clone(this.search_data);
      // console.log('searchdata pas clone', searchData)

      searchData.rooms[item.guestIndex] = item.guests;

      // // menyra 1
      // let childrenAgesArr = [];
      // for (let i = 0; i < item.children_ages.length; i++) {
      //   childrenAgesArr.push(item.children_ages[i]);
      // }

      // console.log('childrenArr',childrenAgesArr)

      // // add children_ages params in select room step whenever we increase children stepper

      // if(this.propertyDetails.application_configuration.enable_children_ages) {
      //   searchData.children_ages.push(childrenAgesArr[childrenAgesArr.length - 1]);
      // }


      //menyra 2 bon nese ja shtojme nje variable kontrolluese
      // if(this.propertyDetails.application_configuration.enable_children_ages) {
      //   item.children_ages.concat(searchData.children_ages)
      //   console.log('item children test',item.children_ages)
      // }




      this.SET_SEARCH_DATA(searchData);
      this.$forceUpdate();

      let payload = {
        roomIndex,
      };
      if (this.$route.query.hasOwnProperty("rate_id")) {
        payload.rate_code_ids = this.$route.query.rate_id;
      }
      this.removeRoom();
      this.getRoomAvailableRates(payload);
    },
    bookWithPointsStatus(){
      let rate_code_ids = null
      if (this.$route.query.hasOwnProperty('rate_id')) {
        rate_code_ids.rate_code_ids = this.$route.query.rate_id
      }
      this.updateSelectedRoom({ action:"REMOVE_ALL" })
      let searchDataLength = this.search_data.rooms.length
      for(let i = 0; i < searchDataLength; i++){
        let payload = {
          rate_code_ids,
          roomIndex: i
        }
        this.getRoomAvailableRates(payload)
      }
    },
    getProfile(selectedDates, roomIndex) {
      this.onDateSelected(selectedDates, roomIndex);
      this.headerKey++;
    },
    removeRoom() {
      let payload = {
        action: "REMOVE",
        room_details: { room_no: +this.activeRoomIndex },
      };
      this.updateSelectedRoom(payload);
    },
    changeRoomKey() {
      this.roomsKey++;
    },
    translateValues(object_code, value, object_type, object_id, field_name) {
      return translateDynamicContent(this.multi_lingual_dynamic_fields, object_code, value, object_type, this.property_language_code, object_id, field_name)
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/sass/config/mixins";
@import "../../../assets/sass/config/variables";
.rooms-drop-down {
  ::v-deep .el-input__inner {
    height: 55px;
  }
}

@include media(">=tablet") {
  .filters {
    border: solid #dadada 1px;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    border-radius: 5px;

    .date-filter {
      min-width: 450px;
    }

    .rooms {
      margin-left: 10px;
    }

    .guests {
      width: 650px;

      .dropdown-custom {
        -webkit-appearance: none;
        background-color: #fff;
        background-image: none;
        border-radius: 4px;
        border: 1px solid #dcdfe6;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        color: #606266;
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 40px;
        outline: 0;
        padding: 0 15px;
        -webkit-transition: border-color 0.2s
          cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        margin-left: 10px;
      }
    }

    .guests-dropdown {
      padding: 20px 20px 0 20px;
      width: 250px;

      .room-number {
        display: flex;
        margin-bottom: 5px;
        color: #b1b1b1;
        font-size: 12px;
      }

      .add-another-room {
        font-size: 11px;
        margin-top: 3px;
        cursor: pointer;
      }

      .rooms-chosen-dropdown {
        margin-bottom: 10px;
        border-bottom: 1px solid #dadada;

        > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
        }

        .room-number {
          display: flex;
          margin-bottom: 5px;
          color: #b1b1b1;
        }

        .remove {
          color: #409eff;
          cursor: pointer;
        }
      }
    }

    .tag {
      border: 1px solid $hk-primary-color;
      color: $hk-primary-color;
      text-align: center;
      font-weight: 500;
      padding: 12px 20px;
      font-size: 14px;
      border-radius: 4px;
      height: 53px;
    }
  }
}

@include media("<=tablet") {
  .filters {
    padding: 10px 25px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .date-filter {
      width: 100%;
    }

    .rooms {
      margin-top: 10px;
    }

    .guests {
      width: 100%;
    }
  }
  .btn {
    width: 100%;
    padding: 0 25px;

    .button {
      button {
        width: 100%;
      }
    }
  }
}

.entire {
  position: relative;
  background: white;
  width: 60vw;
  height: 97vh;
  z-index: 2;
  left: -11px;
  top: -14px;
}

.el-tab-pane {
  min-height: 500px;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: none !important;
}

::v-deep .el-tabs--card > .el-tabs__header {
  border-bottom: none;

  .el-tabs__item:first-child {
    border-left: 1px #dadada solid !important;
  }

  .el-tabs__item {
    margin-right: 5px;
    min-width: 200px;
    border-bottom: 1px #dadada solid !important;
    text-align: center;
  }
}

::v-deep .el-tabs__header {
  // margin: 0 !important;
  margin-bottom: 10px;

  .el-tabs__item {
    border: 1px solid #dadada;
    border-bottom: none;
    color: black;
    border-radius: 5px;

    &.is-active {
      background-color: $hk-primary-color;
      color: white;
    }
  }

  .el-tabs__item:first-child {
    border-left: 1px;
  }
}
</style>
