<template>
  <div class="payment-tab">
    <label class="label m-l-10 m-t-10" :class="{ 'req-dot': card_required && externalTokenizer }"
    >{{ $t('lbl_payment_information') }}</label>
    <div class="use-existing-payment m-l-15 m-b-10 m-r-25  small bordered p-20 border-radius-5" v-if="showUseExistingPaymentChkBox()">
      <div class="flexed justify-between">
        <el-radio  :label="true" v-model="useExistingPayment"  @change="$emit('updateShowNextButtonOnPayment', useExistingPayment)">{{ $t("lbl_use_existing_payment_method") }}</el-radio>

        <div :class="!useExistingPayment ? 'text-grey' : ''">
          <span>{{searchReservationResult.reservation_details.payment_sources[0].custom_field_2}}</span>
          <span class="m-l-10">{{searchReservationResult.reservation_details.payment_sources[0].custom_field_1}}</span>
        </div>
      </div>
      <div class="flexed justify-center m-t-20">
        <el-button  type="primary" @click="confirmExistingPayment">{{ $t("lbl_confirm") }}</el-button>
      </div>
    </div>
    <div class="w-100 text-center m-b-10 small" v-if="showUseExistingPaymentChkBox()">Or</div>
    <div class="text-center bordered p-20 m-l-15 m-b-10 m-r-25 border-radius-5" v-if="useExistingPayment">
      <el-button type="primary" @click="useExistingPayment = false">{{ $t("lbl_add_payment_method") }}</el-button>
    </div>
    <div v-if="externalTokenizer && !useExistingPayment" >
      <el-radio-group v-model="selectedGuaranteeTypeId" class="flexed-column gap-10  m-l-15 m-t-10 m-b-10">
        <el-radio :label="guarnateeType.id" v-for="(guarnateeType, index) in availableGuaranteeTypes" :key="index" @change="guarnateeTypeChanged(guarnateeType)">{{translateValues(guarnateeType.code,  guarnateeType.name, 'GUARANTEE_TYPES', guarnateeType.id, 'name' )}}</el-radio>
      </el-radio-group>
      <credit-card-component
        v-if="showCCframe"
        @updateCC="cardDetailsAdded($event)"
        :guest-email="guest.email"
      />
    </div>
    <span class="help is-danger" style="font-size: 15px; margin-top: 10px"
          v-if="creditCardRequired">{{ $t('lbl_credit_card_required') }}</span>


  </div>
</template>

<script>
import {EventBus} from '@/components/wizard_bus';

import {mapState, mapActions, mapMutations} from 'vuex';
import CreditCardComponent from '@/components/credit-card-component'
import ApiCalls from '@/services/api-calls.service.js'
import { translateDynamicContent } from "@/services/utilities.service";


export default {
  components: {CreditCardComponent},
  props: ['nextStep'],
  async beforeMount() {
    await this.getAvailableGuaranteeTypes()
    if(this.showUseExistingPaymentChkBox()){
      this.useExistingPayment = true
      this.$emit('updateShowNextButtonOnPayment', true)
      this.$forceUpdate()
    } else this.$emit('updateShowNextButtonOnPayment', !this.selectedGuaranteeType?.payment_type_required)

    // below state is used on booking-engine steps to show Continue button
    // when guarantee type is required but the payment frame is not displayed
    this.SHOW_PAYMENT_FRAME(this.showCCframe)
  },
  data: () => ({
    card_logo: null,
    useExistingPayment: false,
    cards: [
      {
        logo_url: '@/assets/static/supported_cards/american_express.svg',
        name: 'American Express',
      }, {
        logo_url: '@/assets/static/supported_cards/visa.svg',
        name: 'Visa',
      }, {
        logo_url: '@/assets/static/supported_cards/diners_club.svg',
        name: 'Diners Club',
      }, {
        logo_url: '@/assets/static/supported_cards/jcb.svg',
        name: 'JCB',
      }, {
        logo_url: '@/assets/static/supported_cards/discover.svg',
        name: 'Discover',
      }, {
        logo_url: '@/assets/static/supported_cards/mastercard.svg',
        name: 'Mastercard',
      }],
    card: {
      card_number: null,
      expiration_month: null,
      expiration_year: null,
      card_type: null,
    },
    valid_status: false,
    payment_type: null,
    token: null,
    months: [
      {
        name: 'January',
        value: 1
      }, {
        name: 'February',
        value: 2
      }, {
        name: 'March',
        value: 3
      }, {
        name: 'April',
        value: 4
      }, {
        name: 'May',
        value: 5
      }, {
        name: 'June',
        value: 6
      }, {
        name: 'July',
        value: 7
      }, {
        name: 'August',
        value: 8
      }, {
        name: 'September',
        value: 9
      }, {
        name: 'October',
        value: 10
      }, {
        name: 'November',
        value: 11
      }, {
        name: 'December',
        value: 12
      }],
    tokenizedCardDetails: null,
    creditCardRequired: false,
    payIsFromCreditCard: false,
    availableGuaranteeTypes: [],
    selectedGuaranteeTypeId: '',
    isFulfilled: false,
    skip_payment_stcc: false
  }),
  computed: {
    ...mapState({
      hotel: state => state.property.details,
      reservation_info: state => state.stay_details,
      guest: state => state.guest_information,
      current_step: state => state.current_step,
      booking_color: state => state.booking_color,
      wizard_loading: state => state.loading,
      accessibility_mode: state => state.access_data,
      profile: state => state.profile,
      booking_data: state => state.booking_data,
      searchReservationResult:  state => state.searchReservationResult,
      editStayDetails: state => state.editStayDetails,
      booking_profile_guarantee_types: state => state.profile.restrictions.booking_profile.booking_profiles_guarantee_types,
      multi_lingual_dynamic_fields: state => state.multi_lingual_dynamic_fields,
      property_language_code: state => state.property.details.language
    }),
    showCCframe(){
      if(!this.selectedGuaranteeType?.code?.endsWith('CC'))
        return false;

      let payment_type = this.booking_profile_guarantee_types.find(item => {
        return item.id === this.selectedGuaranteeType.id
      })
      return payment_type ? !payment_type.instant_booking : false
    },
    buttonStyle() {
      return {
        'background-color': `rgba(${this.booking_color}, 1)`,
        'box-shadow': `0 16px 26px -10px rgba(${this.booking_color}, .56), 0 4px 25px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(${this.booking_color}, .2)`
      }
    },
    bookingPageLayout() {
      if (this.accessibility_mode) {
        return 'VERTICAL'
      } else {
        return this.hotel.website_config.booking_page_layout
      }
    },
    externalTokenizer() {
      return process.env.VUE_APP_TOKENIZER_ENABLED === 'true' && this.hotel.website_config.app_id
    },
    card_required() {
      return false //this.profile.restrictions.booking_profile.guarantee_required_to_book
    },
    checkForPaymentSources(){
      return  (this.searchReservationResult?.reservation_details?.payment_sources
        && this.searchReservationResult?.reservation_details?.payment_sources.length > 0)
    },
    canPayWithExistingPaymentSource(){
      return this.useExistingPayment && this.editStayDetails && this.checkForPaymentSources
    },
    selectedGuaranteeType(){
      return this.availableGuaranteeTypes?.find(g=>g.id === this.selectedGuaranteeTypeId)
    }
  },
  methods: {
    ...mapMutations({
      CHANGE_STEP: 'CHANGE_STEP',
      WIZARD_LOADING: 'WIZARD_LOADING',
      SET_BOOKING_DATA: 'SET_BOOKING_DATA',
      SHOW_PAYMENT_FRAME: 'SHOW_PAYMENT_FRAME'
    }),
    ...mapActions({
      createReservation: 'createReservation'
    }),

    guarnateeTypeChanged(guarnateeType){
      this.$emit('updateShowNextButtonOnPayment', !guarnateeType.payment_type_required)
      this.SHOW_PAYMENT_FRAME(this.showCCframe)
    },
    async getAvailableGuaranteeTypes() {
      this.WIZARD_LOADING(true);
      try {
        const guarantyUrl = `${this.hotel.id}/booking-engine/new-booking/${this.booking_data.sessions[0].session_id}/available-guarantee-types`
        this.availableGuaranteeTypes = (await ApiCalls.get(guarantyUrl, {}, 'propertyUrl'))?.data?.guarantee_types || []
        if(this.availableGuaranteeTypes?.length) {
          this.selectedGuaranteeTypeId = this.availableGuaranteeTypes[0].id

          if(this.availableGuaranteeTypes?.length === 1 && this.availableGuaranteeTypes[0].code === 'STCC') {
            this.skip_payment_stcc = true
          }
        }

        else{
         await this.makePayment(true)
          return
        }
      } catch (availableGuarnateeError) {
        this.availableGuaranteeTypes = []
        console.log({availableGuarnateeError})
      }
      this.WIZARD_LOADING(false)
    },
    showUseExistingPaymentChkBox(){
      return this.editStayDetails && this.searchReservationResult && this.checkForPaymentSources
    },
    async prevStep() {
      await this.CHANGE_STEP('information')
    },
   async validateChildren() {
      //if use is on edit booking flow and tokenizedCardDetails and useExistingPayment are null, show the error message
      if(this.showUseExistingPaymentChkBox() && !this.tokenizedCardDetails && !this.useExistingPayment){
        this.$notify.error({
            title: this.$t('lbl_payment_method_required'),
            message: this.$t('lbl_please_select_a_payment_method') 
          })
        return
      }
      if (this.externalTokenizer) {
        if (this.tokenizedCardDetails || (this.canPayWithExistingPaymentSource)) {
         await this.makePayment()
        } else if (this.tokenizedCardDetails === null && this.card_required) {
          this.creditCardRequired = true
        } else if (this.tokenizedCardDetails === null && this.card_required === false) {
         await this.makePayment()
        }
      } else {
        EventBus.$emit('validate');
      }
    },
   async makePayment(skipPayment) {
      this.WIZARD_LOADING(true);
      // if tokenizedCardDetails has values(the user has entered credit card details) or user uses exitsing payment source(while modifing existing booking)
      if (this.tokenizedCardDetails || this.canPayWithExistingPaymentSource) {
        let payload = {
          guarantee_details: (this.payIsFromCreditCard && this.tokenizedCardDetails) ?
            // set the guarantee_details from credit card
            {
              payment_type_id: null,
              custom_field_1: this.tokenizedCardDetails.masked_card_number,
              custom_field_2: this.tokenizedCardDetails.card_brand,
              custom_field_3: this.tokenizedCardDetails.card_holder_name,
              custom_field_4: this.tokenizedCardDetails.card_expire_month + "/" + this.tokenizedCardDetails.card_expire_year,
              custom_field_5: this.tokenizedCardDetails.token,
            } :
            // set the guarantee_details from existing payment source, while modifing booking
            {
              payment_type_id: null,
              custom_field_1: this.searchReservationResult?.reservation_details?.payment_sources[0].custom_field_1,
              custom_field_2: this.searchReservationResult?.reservation_details?.payment_sources[0].custom_field_2,
              custom_field_3: this.searchReservationResult?.reservation_details?.payment_sources[0].custom_field_3,
              custom_field_4: this.searchReservationResult?.reservation_details?.payment_sources[0].custom_field_4,
              custom_field_5: this.searchReservationResult?.reservation_details?.payment_sources[0].custom_field_5,
            }
        }
        //attach payload
       await this.setBookingData(payload)
        //clear value for payIsFromCreditCard
        this.payIsFromCreditCard = false
        //create reservation
        return this.createReservation()

      }
      else if(skipPayment){
       await this.setBookingData()
        this.payIsFromCreditCard = false
        return this.createReservation()
      }
      // if guaranty is not required to book or mop is equal to 'COMPANY'
      else {
       await this.setBookingData()
        this.payIsFromCreditCard = false
        return this.createReservation()
      }
    },
    cardDetailsAdded(event) {
      //when user clicks on Pay button inside credid card component
      if (event.status.toLowerCase() === 'success') {
        this.creditCardRequired = false;
      }
      this.payIsFromCreditCard = true
      this.tokenizedCardDetails = event.ccDetails
      this.makePayment()
    },
    confirmExistingPayment(){
      this.payIsFromCreditCard = false
      this.validateChildren();
    },
    setBookingData(payload){
      let data = [];
      this.booking_data.sessions.forEach(item => {
        let guest_data = {
          session_id: item.session_id,
          guest_data: item.guest_data,
          stay_remarks: item.stay_remarks,
          remarks: item.remarks,
          defined_guarantee_type_id : this.selectedGuaranteeType?.id || null,
          guarantee_details: payload?.guarantee_details || {payment_type_id: null},
        }
        data.push(guest_data)

      })
      let final_data = {
        sessions: data
      }
      this.SET_BOOKING_DATA(final_data);
    },
    translateValues(object_code, value, object_type, object_id, field_name) {
        return translateDynamicContent(this.multi_lingual_dynamic_fields, object_code, value, object_type, this.property_language_code, object_id, field_name)
    }
  },
  watch: {
    selectedGuaranteeType(newVal, oldVal) {
      if(newVal) {
        if(this.skip_payment_stcc ) {
          this.nextStep()
        }
      }
    }
  },
  beforeDestroy () {
    this.$emit('updateShowNextButtonOnPayment', false)
  },
};

</script>

<style lang="scss" scoped>
@import '~mixins';
@import '~css_vars';

.payment-tab {
  width: 100%;
  max-width: 550px;
  margin: 0 auto;

  &.vertical {
    max-width: 100%;

    .loader {
      svg {
        width: 15px;
        height: 15px;
      }
    }
  }

  .label {
    font-size: 16px;
    font-weight: bold;
    display: block;
    margin-bottom: 15px;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;

    button {
      border: none;
      display: flex;
      justify-content: space-around;
      min-width: 140px;
      padding: 12px 30px;
      border-radius: 3px;
      position: relative;
      font-size: 12px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 0;
      color: white;
      will-change: box-shadow, transform;
      transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);

      .next {
        display: flex;
        justify-content: space-around;
        background-color: $blue;
        margin-left: auto;
        box-shadow: 0 2px 2px 0 rgba($blue, 0.14), 0 3px 1px -2px rgba($blue, 0.2), 0 1px 5px 0 rgba($blue, 0.12);
      }
    }
  }
}

.shared-card {
  font-size: 15px;
  width: 90%;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #dcdfe6;
  display: flex;
  justify-content: space-between;
  margin-left: 4%;

  .card-type {
    display: flex;
    align-items: center;
    margin: 3px;
    padding: 8px;
    border-right: 1px solid #dcdfe6;

    img {
      width: 40px;
    }
  }

  .card-numbers {
    padding: 8px;
    display: flex;
    align-self: center;
  }

  .exp-date {
    padding: 8px;
    display: flex;
    align-self: center;
    border-left: 1px solid #dcdfe6;
  }

}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.pay-method {
  margin: 0 20px;

  p {
    font-size: 1rem;
    font-weight: 700;
    font-family: 'Lato';
    margin: 0;
  }

  .main-item {
    display: flex;
    justify-content: space-between;

    .pay-item {
      flex: 1;
      min-width: 150px;
      height: 100px;
      border: 1px solid #dedede;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer;

      span {
        font-size: 1rem;
      }

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .check-icon {
        position: absolute;
        right: 10px;
        bottom: 10px;
        color: $blue;
      }
    }

    .guest {
      margin: 10px 10px 20px 0;
    }

    .company {
      margin: 10px 0 20px 10px;
    }
  }
}


.company-message {
  font-size: 1rem;
  font-weight: 400;
  max-width: 480px;
  margin: 10px 20px;
}

@include media('<=phone') {
  .payment-tab {
    .use-existing-payment{
      padding: 0;
      padding: 0 10px;
      border: none;
    }
  }
}

</style>
