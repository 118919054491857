<template>
  <div style="width:100%;" class="confirmation">

    <h2 aria-label="Confirmation Tab" style="margin: 0; height: 0;"></h2>
    <div v-if="wizard_loading" style="width: 100%" class="loader loading_whole">
      <loader></loader>
    </div>
    <div v-else-if="(!confirmed_rooms_data || confirmed_rooms_data.length === 0)"
         class="failed-reservations">
      <h3>
        <span><fail class="failed"></fail></span> <span>{{ $t('lbl_reservation_not_complete_booking_web') }}</span>
      </h3>
      <div class="navigation">
        <a href="./" class="finish">
          <button class="next">{{ $t('lbl_try_again') }}</button>
        </a>
      </div>
    </div>
    <div tab-index="1" v-else-if="confirmed_rooms_data" class="reservation-succeeded" v-loading='localLoading'>
      <div>
        <div class="container">
          <div class="flexed-wrap gap-10 justify-between align-center reservation-header m-b-10">
            <div class="confirm-title">
              <span><checked class="checked"></checked></span>
              <div class="text-center flexed-wrap justify-center reservation-succeed-message">

                <span class="m-r-5" v-if="editStayDetails"> {{ $t("lbl_your_stay_details_have_been_modified_successfully") }} </span>
                <span class="m-r-5" v-else> {{ $t('lbl_reservation_confirmed') }} </span>
                <span v-if="!editStayDetails">
                  <span v-if="!enable_multi_room_booking">
                    {{ confirmed_rooms_data.length }} {{ confirmed_rooms_data.length > 1 ? `${$t('lbl_rooms')}` : `${$t('lbl_room')}` }}
                  </span>
                  <span v-else>
                    {{ number_of_rooms }} {{ number_of_rooms > 1 ? `${$t('lbl_rooms')}` : `${$t('lbl_room')}` }}
                  </span>
            </span>
              </div>
            </div>
            <el-button type="primary" @click="gotoReservationDetails()" v-if="editStayDetails && !mobileView"> {{ $t("lbl_view_reservation_details") }}</el-button>
            <el-button type="primary" @click="bookAnotherReservation()" v-else-if="!mobileView"> {{ $t("lbl_book_another_reservation") }}</el-button>
          </div>
          <div v-for="(confirmed, index) in confirmed_rooms_data" :key="index">
            <div class="confirmation_number" :class="`activate-${index}`" :id="`activate-pixel-${index}`">
            <span>
              {{ $t('lbl_confirmation_number') }}:
              <span class="comf-number">{{ confirmed.confirmation_no }}</span>
            </span>
              <!--            <span @click="getConfirmationLetter(confirmed.id)"><printer-icon></printer-icon></span>-->
            </div>
            <selected-room
              :room_details="confirmed"
              :index="index"
              :display_code="selected_room_details[index].rate.display_code"
              :room_id="selected_room_details[index].room.id"
              :room_code="selected_room_details[index].room.code"
              :rate="selected_room_details[index].rate"
            >
            </selected-room>
          </div>
          <el-button type="primary" @click="gotoReservationDetails()" v-if="editStayDetails && mobileView" class="w-100"> {{ $t("lbl_view_reservation_details") }}</el-button>
          <el-button type="primary" @click="bookAnotherReservation()" v-else-if="mobileView" class="w-100"> {{ $t("lbl_book_another_reservation") }}</el-button>
        </div>
      </div>
      <instant-payment-handler v-if="instantBooking" :public_link="public_link"/>
    </div>
  </div>
</template>
<script>
import {AlertCircleIcon, CheckCircleIcon, LoaderIcon} from 'vue-feather-icons';
import {mapActions, mapMutations, mapState} from 'vuex'
import selectedRoom from './selected-room.vue'
import format from 'date-fns/format'
import deepClone from 'clone'
import ApiCalls from '@/services/api-calls.service.js'
import DirectPaymentModal from "./DirectPaymentModal.vue";
import InstantPaymentHandler from "./InstantPaymentHandler.vue";

export default {
  mounted() {

    this.categories.forEach(category => {
      category.image = this.the_web_images[category.name].url || `@/assets/static/explore_categories/${category.name}.jpg`;
    });
    if (this.bookingPageLayout === 'VERTICAL') {
      document.getElementsByClassName('reservation-succeeded')[0]?.scrollIntoView({behavior: 'smooth'})
    }
    this.$emit('injectSorjenScript', 'CONVERSION')

  },
  components: {
    InstantPaymentHandler,
    DirectPaymentModal,
    checked: CheckCircleIcon,
    Loader: LoaderIcon,
    fail: AlertCircleIcon,
    selectedRoom
  },
  computed: {
    ...mapState({
      booking_color: state => state.booking_color,
      hotel: state => state.property.details,
      confirmed_rooms_data: state => state.confirmed_rooms_data,
      wizard_loading: state => state.loading,
      searchReservationResult:  state => state.searchReservationResult,
      editStayDetails: state => state.editStayDetails,
      propertyDetails: (state) => state.property?.details,
      mobileView: state => state.mobileView,
      booking_profile_guarantee_types: state => state.profile.restrictions.booking_profile.booking_profiles_guarantee_types,
      booking_data: state => state.booking_data,
      selected_room_details: state => state.selected_room_details,
      enable_multi_room_booking: state => state.property.details.website_config?.enable_multi_room_booking,
      number_of_rooms: state => state.number_of_rooms,

    }),
    instantBooking(){
      let res = false
      let selected_guarantee_types = this.booking_data.sessions.map(session =>  session.defined_guarantee_type_id)
      this.booking_profile_guarantee_types.forEach(item => {
        if(item.instant_booking && selected_guarantee_types.find(s => s === item.id))
          res = true
      })
      return res
    },
    currentStep() {
      return this.$store.state.current_step;
    },
    steps() {
      return this.$store.state.steps;
    },
    property() {
      return this.$store.state.property.details;
    },
    the_web_images() {
      let to_extract = this.categories.map(category => category.name);
      let new_struct = {};
      to_extract.forEach(extract => {
        let struct = {
          title: extract,
          url: null,
        };
        if (!!this.web_config && this.web_config.hasOwnProperty(extract.toUpperCase()) && this.web_config[extract.toUpperCase()].length > 0) {
          struct.url = this.web_config[extract.toUpperCase()][0].image_url;
        }
        if (struct.url === null) {
          struct.url = `@/assets/static/explore_categories/${extract}.jpg`;
        }
        new_struct[extract] = struct;
      });
      return new_struct;
    },
    web_config() {
      return this.$store.state.web_config && this.$store.state.web_config.property_website_images;
    },
    bookingPageLayout() {
      return this.property.website_config.booking_page_layout
    },
    public_link(){
      let public_link = this.confirmed_rooms_data[0]?.public_link
      return `${public_link}&embedded=true&go_to_payment_link=true`
    }
  },
  data() {
    return {
      categories: [
        {
          id: 1,
          image: null,
          name: 'RESTAURANTS',
          tags: ['restaurants'],
        }
      ],
      current_image: null,
      localLoading: false,
    }
  },
  methods: {
    ...mapMutations({
      CHANGE_STEP: 'CHANGE_STEP',
      WIZARD_LOADING: 'WIZARD_LOADING',
      SET_SEARCH_RESERVATION_RESULT: 'SET_SEARCH_RESERVATION_RESULT'
    }),
    ...mapActions({
      resetStateAndGotoResrvation: 'resetStateAndGotoResrvation'
    }),
    bookAnotherReservation() {
      window.location.reload()
    },
    findFirstStep() {
      let arrayOfSteps = Object.entries(this.steps).map(s => s)
      return arrayOfSteps.find(s => s[1].index === 0)[1];
    },
    clearData() {
      if (this.$route.query.hasOwnProperty('url')) {
        window.location.href = decodeURIComponent(this.$route.query.url)
      } else if (this.$route.query.tk) {
        let decrypted = atob(this.$route.query.tk);
        let userInfo = JSON.parse(decrypted);
        window.location.href = userInfo.url
      } else {
        window.location.reload()
      }
    },
    getConfirmationLetter(room_id) {
      let property = this.property;
      let device_time = format(new Date(), 'YYYY-MM-DD hh:mm A');
      let base_url = this.property.region.url || process.env.VUE_APP_SERVER_ENV

      let url = `${base_url}${process.env.VUE_APP_BASE_URL}properties/${property.id}/reports/confirmation-letter/${room_id}?DEVICE_TIME=${device_time}&TO_CURRENCY=${property.currency}&language_code=${property.language}`;
      window.open(url);
    },
    async gotoReservationDetails() {
      let params = {
        conf_num: this.confirmed_rooms_data[0].confirmation_no,
        last_name: this.confirmed_rooms_data[0].last_name,
        property_code: this.propertyDetails.code
      }
      this.localLoading = true
      try {
        let searchResult = (await ApiCalls.get('web/manage-booking', params, 'enterpriseUrl'))?.data
        this.SET_SEARCH_RESERVATION_RESULT({...searchResult, conf_num: params.conf_num});
        this.resetStateAndGotoResrvation('search-reservation-result')
      } catch (error) {
        console.log(error)
      }
      this.localLoading = false
    },
  },
};
</script>
<style scoped lang="scss">
@import "src/assets/sass/config/mixins";
@import "src/assets/sass/config/variables";

.container {
  max-width: 750px;
  margin: 0 auto;
}


.checked {
  stroke: $green;
}

.confirm-title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 18px;
  font-weight: 600;

  > span:first-child {
    display: flex;
    margin-right: 10px;
  }
}

.next {
  width: 100%;
  touch-action: manipulation;
  border: none;
  min-width: 140px;
  padding: 12px 30px;
  border-radius: 3px;
  position: relative;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0;
  will-change: box-shadow, transform;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: $blue;
  margin-left: auto;
  color: white;
  box-shadow: 0 2px 2px 0 rgba($blue, 0.14), 0 3px 1px -2px rgba($blue, 0.2), 0 1px 5px 0 rgba($blue, 0.12);
}

#action_buttons {
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    background: #61d095;
    color: #fff;
    --webkit-apperance: none;
    padding: 1rem 2rem;
    font-size: .85rem;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    font-weight: 700;
    font-family: 'Lato';
    letter-spacing: 1px;
  }
}

.confirmation_number {
  border-radius: 4px;
  background-color: whitesmoke;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  padding: .5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  > :last-child {
    cursor: pointer;
    font-size: 15px;
  }
}

.comf-number {
  color: darken($green, 10);
  font-weight: bold;
}

.selected_room.card {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

@include media('<=tablet') {
  h3 {
    font-size: 1.4rem;
  }
  .confirmation_number {
    font-size: 1rem;
  }
  .confirmation {
    padding: 20px;
  }
}

@include media('<=phone') {
  .reservation-succeeded {
    h3 {
      font-size: 16px;
    }
    .reservation-header{
      .el-button{
        width:100%;
      }
    }
  }
}

.finish {
  margin-left: auto;
  text-decoration: none;
}


.failed-reservations {
  padding-top: 40px;
}

.failed {
  stroke: $red;
}

.reservation-succeeded {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

</style>
